<template>
  <svg
    id="edb39be2-7d8f-4167-bf48-8abe8bdb806a"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 37"><path
      d="M7.38,0H29.63A7.37,7.37,0,0,1,37,7.37V29.62A7.37,7.37,0,0,1,29.63,37H7.37A7.37,7.37,0,0,1,0,29.62V7.38A7.38,7.38,0,0,1,7.38,0Z"
      class="icon-wrapper__template"
      :fill="color" /><path
        d="M25.87,11c-2.12-2.12-4.4-.35-6.81-.15V8.33a.72.72,0,0,0-.74-.74A10.22
        ,10.22,0,0,0,11.16,11a.73.73,0,0,0-.21.52v17a.74.74,0,0,0,.74.74H25.33a.74.74
        ,0,0,0,.75-.74v-17A.73.73,0,0,0,25.87,11Zm-13.6,2.79a.39.39,0,0,1
        ,.39-.38h4.7a.38.38,0,0,1,.35.22v1.63a.38.38,0,0,1-.35.22h-4.7a.39.39
        ,0,0,1-.39-.38Zm0,3a.39.39,0,0,1,.39-.38h4.7a.38.38,0,0,1,.35.22v1.64a.38.38
        ,0,0,1-.35.22h-4.7a.39.39,0,0,1-.39-.39Zm0,2.94a.39.39,0,0,1,.39-.39h4.7a.38.38
        ,0,0,1,.35.22v1.64a.38.38,0,0,1-.35.22h-4.7a.39.39,0,0,1-.39-.38Zm5.09
        ,8.12h-4.7a.39.39,0,0,1-.39-.38v-4.5a.39.39,0,0,1,.39-.38h4.7a.38.38,0,0
        ,1,.35.22v4.82A.38.38,0,0,1,17.36,27.8Zm7.36-.38a.39.39,0,0,1-.39.38H19.46a.39.39
        ,0,0,1-.39-.38v-4.5a.39.39,0,0,1,.39-.38h4.87a.39.39,0,0,1,.39.38Zm0-6.43a.39.39
        ,0,0,1-.39.38H19.46a.39.39,0,0,1-.39-.38V19.69a.39.39,0,0,1,.39-.39h4.87a.39.39
        ,0,0,1,.39.39Zm0-2.94a.39.39,0,0,1-.39.39H19.46a.39.39,0,0,1-.39-.39V16.75a.39.39
        ,0,0,1,.39-.38h4.87a.39.39,0,0,1,.39.38Zm0-3a.39.39,0,0,1-.39.38H19.46a.39.39,0
        ,0,1-.39-.38V13.75a.39.39,0,0,1,.39-.38h4.87a.39.39,0,0,1,.39.38Z"
        transform="translate(-0.01 -0.03)"
        fill="#fff" /></svg>
</template>
<script>
import ProjectIconTemplate from '@/mixins/ProjectIconTemplate';
export default {
  mixins: [ProjectIconTemplate],
};
</script>